<template>
  <div class="auth-wrapper auth-v1 d-flex flex-wrap text-din-regular" style="height: 100vh;">
    <div class="auth-inner d-flex py-2 align-items-center" style="height: 95%;">
      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-card-title class="mb-1">
          Forgot Password? 🔒
        </b-card-title>
        <b-card-text class="mb-2">
          Enter your email and we'll send you instructions to reset your
          password
        </b-card-text>

        <!-- form -->
        <validation-observer ref="validateForm">
          <b-form
            class="auth-forgot-password-form mt-2"
            @submit.prevent="forgotPass"
          >
            <!-- email -->
            <b-form-group
              label="Email Address"
              label-for="forgot-password-email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="forgot-password-email"
                  v-model="userEmail"
                  :state="errors.length > 0 ? false : null"
                  name="forgot-password-email"
                  placeholder="john@example.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <SpinnerBtn v-if="isLoading" />
            <!-- submit button -->
            <b-button
              v-else
              variant="primary"
              block
              type="submit"
              class="text-din-black"
            >
              Send reset link
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <b-link :to="{ name: 'auth-login' }">
            <feather-icon icon="ChevronLeftIcon" /> Back to login
          </b-link>
        </b-card-text>
      </b-card>
      <!-- /Forgot Password v1 -->
    </div>

    <div class="w-100" style="height: 5%; margin-inline:0.5em;">
      <AppFooter class="m-0 p-0 w-100" style="height: 2em;"/>
    </div>
  </div>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import AppFooter from '@/layouts/components/AppFooter.vue'
import {
  BCard,
  BLink,
  BCardText,
  BCardTitle,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import SpinnerBtn from '@/layouts/components/spinners/SpinnerButton.vue'

export default {
  components: {
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    SpinnerBtn,
    ValidationProvider,
    ValidationObserver,
    AppFooter,
  },
  data() {
    return {
      userEmail: '',
      isLoading: false,
      // validation
      required,
      email,
    }
  },
  created() {
    const userEmail = this.$route.query.email
    if (userEmail) {
      this.userEmail = userEmail
    }
  },
  methods: {
    checkEvent() {
      const currentEvent = localStorage.getItem('current_event')
        ? localStorage.getItem('current_event')
        : ''

      if (currentEvent) {
        if (currentEvent.length > 0) {
          return true
        }
        return false
      }
      return false
    },
    forgotPass() {
      this.$refs.validateForm.validate().then(success => {
        if (success) {
          this.isLoading = true
          this.$http
            .post('/api/auth/forgot-password', {
              user: { email_address: this.userEmail },
            })
            .then(response => {
              this.isLoading = false
              if (response.data.errors) {
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Password Reset Failed',
                      icon: 'AlertCircleIcon',
                      text: response.data.errors[0],
                      variant: 'danger',
                    },
                  },
                  { timeout: this.$longestTimeout },
                )
              } else {
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Password Reset',
                      icon: 'AlertCircleIcon',
                      text: response.data.message,
                      variant: 'success',
                    },
                  },
                  { timeout: this.$longestTimeout },
                )
              }
            })
            .catch(error => {
              this.isLoading = false
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Password Reset Failed',
                    icon: 'AlertCircleIcon',
                    text: error.response.data.errors.body[0],
                    variant: 'danger',
                  },
                },
                { timeout: this.$longestTimeout },
              )
            })
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-auth.scss';
</style>
